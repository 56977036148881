export interface Season {
    dateBegin: string;
    dateEnd: string;
    name: string;
    uuid: string;
}

export interface CashLocation {
    name: string;
    uuid: string;
}

export interface BlankReport {
    uuid: string;
    serial: string;
    numberStart: number;
    numberEnd: number;
    status: string;
    datetime: string;
    storehouseUuid: string;
    storehouseType: string;
    storehouseName: string;
    userUuid: string;
    userName: string;
    parentStatus: string;
    parentStorehouseUuid: string;
    parentStorehouseType: string;
    parentStorehouseName: string;
    parentUserUuid: string;
    parentUserName: string;
    range: string;
}

export interface CashRegister {
    name: string;
    uuid: string;
    regNum: number;
    cashRegisterLocationUuid: string;
    workstationUuid: string;
    blankAccounting: boolean;
    reserveMaxQuant: number;
    orderPaymentMinutes: number;
}

export interface CashRegisterLimit {
    cashRegisterLimitType: CashRegisterLimitType;
    cashRegisterUuid: string;
    objectUuid: string;
    uuid?: string;
}

export enum CashRegisterLimitType {
    FUND = 'FUND',
    ZONE = 'ZONE',
    CATEGORY = 'CATEGORY',
    EVENT = 'EVENT',
    EVENT_SESSION = 'EVENT_SESSION',
}

export interface Workstation {
    name: string;
    uuid: string;
}

export interface StoreHouseBlanks {
    datetime: string;
    numberEnd: number;
    numberStart: number;
    serial: string;
    status: string;
    uuid: string;
}

export interface EventType {
    consistType: 'SEASON_TICKET' | 'TICKET';
    name: string;
    uuid: string;
}

export interface Team {
    caption: string;
    name: string;
    uuid: string;
    logoLink: string;
}

export interface Event {
    eventAdvertisement: boolean;
    eventCaption: string;
    eventName: string;
    eventOnSale: boolean;
    eventTypeName: string;
    eventTypeUuid: string;
    eventUpcoming: boolean;
    teamACaption: string;
    teamAName: string;
    teamAUuid: string;
    teamALogoLink: string;
    teamBCaption: string;
    teamBName: string;
    teamBUuid: string;
    teamBLogoLink: string;
    uuid: string;
    selected: boolean;
    status: EventStatus;
}

export enum EventStatus {
    NONE,
    ON_SALE,
    ARCHIVED,
    NOT_ON_SALE,
    ADVERTISEMENT
}

export interface EventFilter {
    seasons: Season[];
    eventTypes: EventType[];
    teams: Team[];
    upcoming: boolean | null;
}

export interface EventSessionType {
    consistType: 'SEASON_TICKET' | 'TICKET';
    name: string;
    uuid: string;
}

export interface EventSessionMatchDay {
    name: string;
    uuid: string;
}

export interface EventSessionLocation {
    name: string;
    uuid: string;
}

export interface EventSessionFilter {
    seasons: Season[];
    eventSessionTypes: EventSessionType[];
    eventSessionMatchDays: EventSessionMatchDay[];
    eventSessionLocations: EventSessionLocation[];
    upcoming: boolean | null;
}

export interface EventSession {
    advertisement: boolean;
    consistSize: number;
    consistType: 'TICKET' | 'SEASON_TICKET';
    dateBegin: string;
    dateEnd: string;
    eventCaption: string;
    eventName: string;
    eventUuid: string;
    eventSessionMatchdayName: string;
    eventSessionTypeName: string;
    eventTypeName: string;
    eventTypeUuid: string;
    locationName: string;
    locationSchemeUuid: string;
    locationUuid: string;
    logoLink: string;
    onSale: boolean;
    seasonName: string;
    teamACaption: string;
    teamAName: string;
    teamAUuid: string;
    teamBCaption: string;
    teamBName: string;
    teamBUuid: string;
    upcoming: boolean;
    uuid: string;
    selected: boolean;
    status: EventStatus;
    datetime: number;
    day: string;
    time: string;
    capacity: number;
    capacityLoading: boolean;
}

export interface EventsSessionsFilter extends EventSessionFilter {
    eventTypes: EventType[];
    teams: Team[];
}

export interface SubscriptionFilter {
    seasons: Season[];
    eventTypes: EventType[];
}

export interface SubscriptionSessionFilter {
    seasons: Season[];
    eventSessionTypes: EventSessionType[];
    eventSessionLocations: EventSessionLocation[];
}

export interface SubscriptionsSessionsFilter extends SubscriptionSessionFilter {
    eventTypes: EventType[];
}

export interface EventSessionLocationScheme {
    locationUuid: string;
    name: string;
    note: string;
    uuid: string;
}

export interface EventSessionFund {
    locationSchemeUuid: string;
    name: string;
    uuid: string;
}

export interface EventSessionZone {
    locationSchemeUuid: string;
    name: string;
    uuid: string;
}

export interface EventSessionSeatview {
    locationSchemeUuid: string;
    name: string;
    uuid: string;
}

export interface EventSessionFull {
    consist: string[];
    consistType: 'SEASON_TICKET' | 'TICKET';
    dateBegin: string;
    dateEnd: string;
    eventCaption: string;
    eventName: string;
    eventSessionMatchdayUuid: string;
    eventSessionTypeUuid: string;
    eventTypeName: string;
    eventUuid: string;
    locationSchemeUuid: string;
    locationUuid: string;
    seasonUuid: string;
    teamACaption: string;
    teamAName: string;
    teamBCaption: string;
    teamBName: string;
    templateFundUuid: string;
    templateSeatviewUuid: string;
    templateZoneUuid: string;
    uuid: string;
    logoLink: string;

    time: string;
    day: string;
}

export interface Fund {
    color: string;
    customerName: string;
    customerType: 'IN' | 'OUT';
    customerUuid: string;
    locationName: string;
    locationUuid: string;
    name: string;
    saletype: 'ALL' | 'OWNER' | 'NOBODY';
    uuid: string;
}

export interface Zone {
    color: string;
    locationName: string;
    locationUuid: string;
    name: string;
    uuid: string;
    order: number;
}

export interface Seatview {
    color: string;
    name: string;
    uuid: string;
}

export interface Category {
    caption: string;
    name: string;
    uuid: string;
    order: number;
}

export interface CopyEventSessionPricesFilter {
    seasons: Season[];
    eventSessionTypes: EventSessionType[];
    eventSessionMatchDays: EventSessionMatchDay[];
}


export interface CopySubscriptionSessionPricesFilter {
    seasons: Season[];
    eventSessionTypes: EventSessionType[];
}

export interface FundTemplate {
    locationName: string;
    locationSchemeName: string;
    locationSchemeUuid: string;
    locationUuid: string;
    name: string;
    uuid: string;
}

export interface SeatviewTemplate {
    locationName: string;
    locationSchemeName: string;
    locationSchemeUuid: string;
    locationUuid: string;
    name: string;
    uuid: string;
}

export interface ZoneTemplate {
    locationName: string;
    locationSchemeName: string;
    locationSchemeUuid: string;
    locationUuid: string;
    name: string;
    uuid: string;
}

export interface LocationScheme {
    locationUuid: string;
    name: string;
    note: string;
    uuid: string;
}

export interface Location {
    name: string;
    uuid: string;
}

export interface SectorInfo {
    prefix: string;
    fullname: string;
    logoLink: string;
    name: string;
    uuid: string;
}

export interface FundTemplateFund {
    color: string;
    name: string;
    saletype: 'ALL' | 'OWNER' | 'NOBODY';
    seatsCount: number;
    uuid: string;
}

export interface FundTemplateStats {
    funds: FundTemplateFund[];
    seatsWithoutFundCount: number;
    totalSeatsCount: number;
}

export interface ZoneTemplateZone {
    color: string;
    name: string;
    seatsCount: number;
    uuid: string;
}

export interface ZoneTemplateStats {
    zones: ZoneTemplateZone[];
    seatsWithoutFundCount: number;
    totalSeatsCount: number;
}

export interface SeatviewTemplateSeatview {
    color: string;
    name: string;
    seatsCount: number;
    uuid: string;
}

export interface SeatviewTemplateStats {
    seatviews: SeatviewTemplateSeatview[];
    seatsWithoutFundCount: number;
    totalSeatsCount: number;
}


export interface SchemeSeat {
    availByFunds: boolean;
    ga: boolean;
    locationSchemeSeatsIngroupUuid: string;
    name: string;
    prefix: string;
    reservedQuantity: number;
    seatviewUuid: string;
    uuid: string;
    x: number;
    y: number;
    zoneUuid: string;
    state?: number;
    color?: number | null;
    indicator?: number | null;
}

export interface SchemeInGroup {
    name: string;
    prefix: string;
    uuid: string;
}

export interface SchemeLabel {
    angle: number;
    color: string;
    fontfamily: string;
    fontsize: number;
    locationSchemeSeatsIngroupUuid: string;
    prefix: string;
    text: string;
    uuid: string;
    x: number;
    y: number;
    zIndex: number;
}

export interface SchemePicture {
    uuid: string;
    pictureType: string;
    width: number;
    height: number;
    x: number;
    y: number;
    zIndex: number;
    angle: number;
}
