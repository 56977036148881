import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Order} from '../../pages/orders/types';

@Injectable({
    providedIn: 'root'
})
export class SharedOrderService {
    private order!: Order | null;

    constructor(
        private readonly httpClient: HttpClient,
    ) {
    }

    public loadOrder(orderId: string): Observable<Order> {
        return this.httpClient.get<Order>(`/adminpanelapi/order/${orderId}/info`)
            .pipe(
                tap(order => {
                    this.order = order;

                    if (this.order.payments !== null) {
                        this.order.payments.sort((a, b) => {
                            return a.receiptRegNum - b.receiptRegNum;
                        });
                    }
                })
            );
    }

    public getOrder(): Order | null {
        return this.order;
    }

    public clear(): void {
        this.order = null;
    }
}

